import React, { useEffect, useState } from 'react'
import Sort from '../../components/Sort'
import MainLayout from '../../layout/MainLayout'
import { LOCALES } from '../../locales/locales'
import { TLanguages } from '../../models/ILocales'
import { get_feetbacks, get_feetbacks_by_id } from '../../services/api'


interface Reviews {
    id: number
    reviewName: string
    date: string
    name: string
    surname: string
    patronymic: string
    phone: string
    platform: 'a' | 'i'
    rating: number
}

const StatsReviews = () => {
    const [reviews, setReviews] = useState<Reviews[]>([])
    const [initialReviews, setInitialReviews] = useState<Reviews[]>([])
    const [filterValues, setFilterValues] = useState<any>({})
    const [currentLocale] = useState<TLanguages>(localStorage.getItem('currentLocale') as TLanguages)
    useEffect(() => {
        let arr = initialReviews
        Object.keys(filterValues).forEach(field => {
            arr = arr.filter(i =>
                // @ts-ignore
                i[field]
                    ?.toString()
                    .toLowerCase()
                    // @ts-ignore
                    .includes(filterValues[field].toString().toLowerCase())
            )
        })
        setReviews(arr)
        // eslint-disable-next-line
    }, [filterValues])

    useEffect(() => {
        const tempReviews: Reviews[] = []
        get_feetbacks().then(res => {
            if (res.data.length) {
                res.data.forEach(feedback => {
                    get_feetbacks_by_id(feedback.url.split('/').pop() as string)
                        .then(res => {
                            res.data.length &&
                                res.data.forEach(someFeedback => {
                                    tempReviews.push({
                                        id: someFeedback.customer?.id,
                                        reviewName: feedback?.title,
                                        date: someFeedback.created,
                                        name: someFeedback.customer?.name,
                                        surname:
                                            someFeedback.customer?.second_name,
                                        patronymic:
                                            someFeedback.customer?.second_name,
                                        phone: someFeedback.customer?.phone,
                                        platform:
                                            someFeedback.customer?.platform,
                                        rating: someFeedback.score
                                    })
                                })
                        })
                        .finally(() => {
                            setReviews([...reviews, ...tempReviews])
                            setInitialReviews([...reviews, ...tempReviews])
                        })
                })
            }
        })
        //eslint-disable-next-line
    }, [])

    return (
        <MainLayout>
            <div className="min-h-40 flex flex-col">
                <span
                    className="cursor-pointer self-end"
                    onClick={() => setFilterValues({})}
                >
                    {LOCALES[currentLocale].CLEAR_FILTERS}
                </span>
                <table className="table-auto w-full">
                    <thead>
                        <tr className="text-md tracking-wide text-left text-gray-900 border-b border-gray-600">
                            <th className="px-4 py-2 table-head">
                                <span>{LOCALES[currentLocale].MAILING_LIST}</span>
                                <Sort
                                    field={'reviewName'}
                                    arr={reviews}
                                    setArr={setReviews}
                                    filterValues={filterValues}
                                    setFilterValues={setFilterValues}
                                />
                            </th>
                            <th className="px-4 py-2 table-head">
                                <span>{LOCALES[currentLocale].DATE}</span>
                                <Sort
                                    field={'date'}
                                    arr={reviews}
                                    setArr={setReviews}
                                    filterValues={filterValues}
                                    setFilterValues={setFilterValues}
                                />
                            </th>
                            <th className="px-4 py-2 table-head">
                                <span>{LOCALES[currentLocale].MAIN_NAME}</span>
                                <Sort
                                    field={'name'}
                                    arr={reviews}
                                    setArr={setReviews}
                                    filterValues={filterValues}
                                    setFilterValues={setFilterValues}
                                />
                            </th>
                            <th className="px-4 py-2 table-head">
                                <span>{LOCALES[currentLocale].LAST_NAME}</span>
                                <Sort
                                    field={'patronymic'}
                                    arr={reviews}
                                    setArr={setReviews}
                                    filterValues={filterValues}
                                    setFilterValues={setFilterValues}
                                />
                            </th>
                            {/*<th className="px-4 py-2"><span>Отчество</span></th>*/}
                            <th className="px-4 py-2 table-head">
                                <span>{LOCALES[currentLocale].PHONE}</span>
                                <Sort
                                    field={'phone'}
                                    arr={reviews}
                                    setArr={setReviews}
                                    filterValues={filterValues}
                                    setFilterValues={setFilterValues}
                                />
                            </th>
                            <th className="px-4 py-2 table-head">
                                <span>{LOCALES[currentLocale].MOBILE_PLATFORM}</span>
                                <Sort
                                    field={'platform'}
                                    arr={reviews}
                                    setArr={setReviews}
                                    filterValues={filterValues}
                                    setFilterValues={setFilterValues}
                                />
                            </th>
                            <th className="px-4 py-2 table-head">
                                <span>{LOCALES[currentLocale].RATING}</span>
                                <Sort
                                    field={'rating'}
                                    arr={reviews}
                                    setArr={setReviews}
                                    filterValues={filterValues}
                                    setFilterValues={setFilterValues}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {reviews.map((review, key) => (
                            <tr
                                className="hover:bg-blue-100 transition-all"
                                key={key}
                            >
                                <td className="px-4 py-2 border text-center">
                                    {review.reviewName}
                                </td>
                                <td className="px-4 py-2 border text-center">
                                    {new Date(review.date).toISOString().split('T')[0]}
                                </td>
                                <td className="px-4 py-2 border text-center">
                                    {review.name}
                                </td>
                                {/*<td className="px-4 py-2 border text-center">*/}
                                {/*    {review.surname}*/}
                                {/*</td>*/}
                                <td className="px-4 py-2 border text-center">
                                    {review.patronymic}
                                </td>
                                <td className="px-4 py-2 border text-center">
                                    {review.phone}
                                </td>
                                <td className="px-4 py-2 border text-center">
                                    {review.platform === 'i'
                                        ? 'apple'
                                        : 'android'}
                                </td>
                                <td className="px-4 py-2 border text-center">
                                    {review.rating}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </MainLayout>
    )
}

export default StatsReviews
