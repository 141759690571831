
import React from "react";
import classes from './WalInput.module.css';

const WalInput: React.FC<any> = ( props ) => {
  return (
        <input className={classes.walInput} {...props}/>
  );
};

export default WalInput;