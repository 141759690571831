import React, { useEffect, useState } from 'react'
import { create_source, list_sources } from '../../services/api'
import { addNotification, setLoading } from '../../store/actions'
import { v4 } from 'uuid'
import { useDispatch } from 'react-redux'
import { TLanguages } from '../../models/ILocales'
import { LOCALES } from '../../locales/locales'
import { IListSource } from '../../models/IApiResponses'

const UTMSource = ({ onClose }: { onClose: () => void }) => {
    const [labelSource, setLabelSource] = useState<string>('')
    const [sourceData, setSourceData] = useState<IListSource[]>([])
    const [currentLocale] = useState<TLanguages>(
        localStorage.getItem('currentLocale') as TLanguages
    )
    const dispatch = useDispatch()

    const handleSave = async () => {
        try {
            await create_source(labelSource)
            onClose()
        } catch (error) {
            dispatch(
                addNotification({
                    id: v4(),
                    type: 'Error',
                    msg: LOCALES[currentLocale].ENTER_CORRECT_VALUES
                })
            )
        }
    }

    useEffect(() => {
        async function fetchSourceData() {
            setLoading(true)
            try {
                const response = await list_sources()
                if (response.data) {
                    setSourceData(response.data)
                }
            } catch (error) {
                dispatch(
                    addNotification({
                        id: v4(),
                        type: 'Error',
                        msg: LOCALES[currentLocale].SERVER_ERROR
                    })
                )
            } finally {
                setLoading(false)
            }
        }

        fetchSourceData()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <button
                onClick={onClose}
                className=" px-2 py-0 rounded  text-2xl  cursor-pointer "
            >
                &#8592;
            </button>
            <div
                className="modal flex justify-center"
                style={{ height: '100vh' }}
            >
                <div className="text-center w-1/3">
                    <h1 className="font-bold text-center mb-6">
                        {LOCALES[currentLocale].CREATE_SOURCE}
                    </h1>
                    <label htmlFor="labelName" >
                        {LOCALES[currentLocale].ENTER_SOURCE_VALUE}
                        <input
                            type="text"
                            id="labelName"
                            value={labelSource}
                            onChange={e => setLabelSource(e.target.value)}
                            className="mx-auto block w-full border border-blue-500 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 mb-6 pl-1"
                            style={{ width: '200px' }}
                        />
                    </label>
                    <button
                        className="mt-2 bg-blue-500 text-white px-3 py-1 rounded mr-2"
                        onClick={handleSave}
                    >
                        {LOCALES[currentLocale].SAVE}
                    </button>
                </div>
                <hr className="h-full mx-4 border-r border-gray-200" />

                <div className="w-1/2">
                    <table
                        className="table-auto w-full ml-2"
                        style={{ textAlign: 'center' }}
                    >
                        <thead>
                            <tr className="text-md tracking-wide  text-gray-900 border-b border-gray-600">
                                <th className="px-6 py-0">
                                    {LOCALES[currentLocale].SOURCE_VALUE}
                                </th>
                                <th className="px-6 py-0">
                                    {LOCALES[currentLocale].UTM_CREATED}
                                </th>
                                <th className="px-6 py-0">
                                    {LOCALES[currentLocale].USERS}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sourceData.map(item => (
                                <tr
                                    key={item.id}
                                    className="hover:bg-blue-100 transition-all"
                                >
                                    <td className="px-6 py-2 border">
                                        {item.source}
                                    </td>
                                    <td className="px-6 py-2 border">
                                        {item.created_at}
                                    </td>
                                    <td className="px-6 py-2 border">
                                        {item.installs}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default UTMSource
