import React, { FormEvent, useEffect, useState } from 'react'
import MainLayout from '../../layout/MainLayout'
import { Redirect } from 'react-router-dom'
import { IState, OrgData } from '../../models/IState'
import { connect, useDispatch } from 'react-redux'
import { create_feetback } from '../../services/api'
import { addNotification } from '../../store/actions'
import { v4 } from 'uuid'
import { LOCALES } from '../../locales/locales'
import { TLanguages } from '../../models/ILocales'

interface AddReviewsProps {
    orgData: OrgData
}

const AddReviews: React.FC<AddReviewsProps> = ({ orgData }) => {
  const dispatch = useDispatch()
  const [title, setTitle] = useState<string>('')
  const [text, setText] = useState<string>('')
  const [negative, setNegative] = useState<string>('Расскажите что вам не понравилось')
  const [thanks, setThanks] = useState<string>('Спасибо за отзыв!')
  const [positive, setPositive] = useState<string>('Спасибо! Оставьте о нас отзыв')
  const [tgBot, settgBot] = useState<boolean>(true)
  const [allow_repeated_feedback, setallow_repeated_feedback] = useState<boolean>(true)
  const [url, setUrl] = useState<string>('')
  const [redirect, setRedirect] = useState(false)
  const [error, setError] = useState('')
  const [currentLocale] = useState<TLanguages>(localStorage.getItem('currentLocale') as TLanguages)
  const [showInputs, setShowInputs] = useState(false);
  const maxTitleLength = 60; 
  const maxTextLength = 150;
  const maxCustomText = 50;

    useEffect(() => {
        setError('')
    }, [text, title])

    const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!title.trim().length || !text.trim().length) {
            return setError(LOCALES[currentLocale].FIELDS_ARE_NOT_FILLED)
        }
        create_feetback(title, text, orgData.orgId, tgBot, thanks, negative, positive, url, allow_repeated_feedback)
            .then(res => {
                if (res.status === 201) {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Success',
                            // msg: `Опрос '${res.data.title}' создан. Ссылка на опрос - https://${res.data.url}`// need to find and use apropriate string.format package
                            msg: res.data.title + ' - ' + LOCALES[currentLocale].POLL_CREATED + ' ' + LOCALES[currentLocale].LINK_TO_POLL +` - https://${res.data.url}`// need to find and use apropriate string.format package
                        })
                    )
                    setRedirect(true)
                } else {
                    setError(LOCALES[currentLocale].SERVER_ERROR)
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Error',
                            msg: LOCALES[currentLocale].SERVER_ERROR
                        })
                    )
                }
            })
            .catch(e => {
                setError(LOCALES[currentLocale].SERVER_ERROR)
                if (e.response.status === 400) {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Error',
                            msg: e.response.data.error
                        })
                    )
                } else {
                  dispatch(
                    addNotification({
                        id: v4(),
                        type: 'Error',
                        msg: LOCALES[currentLocale].SERVER_ERROR
                    })
                )
                }
            })
    }

    if (redirect) {
        return <Redirect to="/admin/reviews" />
    }

  const handleShow = (event: any) => {
    setShowInputs(event.target.checked);
  };

    return (
  <MainLayout>
    <div>
      <form
        onSubmit={e => handleFormSubmit(e)}
        className="w-full flex flex-col gap-2 items-center"
      >
        <input
          type="text"
          maxLength={maxTitleLength}
          placeholder={LOCALES[currentLocale].ENTER_NAME_OF_THE_POLL}
          value={title}
          onChange={e => setTitle(e.target.value)}
          className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
          required
        />
        <textarea
          cols={30}
          rows={5}
          maxLength={maxTextLength}
          placeholder={LOCALES[currentLocale].ENTER_TEXT_OF_THE_POLL}
          value={text}
          onChange={e => setText(e.target.value)}
          className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full resize-none"
          required
        />
        <div className="flex items-center justify-between w-full">
          <div className='flex items-center'>
            <input
              type="checkbox"
              id="sendToTelegram"
              checked={tgBot}
              onChange={e => settgBot(e.target.checked)}
              className="mr-2 w-5 h-5 cursor-pointer hover:cursor-pointer"
            />
                <label className='text-sm font-medium'
                  htmlFor="sendToTelegram">{LOCALES[currentLocale].Display_in_telegram_bot}</label>
              </div>
              </div>
              <div className="flex items-center justify-between w-full">
          <div className='flex items-center'>
            <input
              type="checkbox"
              id="sendToTelegram"
              checked={allow_repeated_feedback}
              onChange={e => setallow_repeated_feedback(e.target.checked)}
              className="mr-2 w-5 h-5 cursor-pointer hover:cursor-pointer"
            />
                <label className='text-sm font-medium'
                  htmlFor="sendToTelegram">{"Повторное прохождение опроса"}</label>
              </div>
              </div>           
            <div className="flex flex-col w-full justify-between">
              <div className="flex items-start mb-4">
                <input
                  type="checkbox"
                  id="sendText"
                  checked={showInputs}
                  onChange={handleShow}
                  className="mr-1 w-5 h-5 cursor-pointer hover:cursor-pointer" />
                <label className="text-sm font-medium" htmlFor="sendText">
                  {LOCALES[currentLocale].Put_custom_text_on_testimonials_page}
                </label>
              </div>
              {showInputs && (
                <>
                  <input
                    className="mb-4 
                    w-full md:w-1/2 
                    px-4 border-black 
                    border rounded-md 
                    text-transparent 
                    text-black"
                    type="text"
                    maxLength={maxCustomText}
                    value={negative}
                    onChange={e => setNegative(e.target.value)}
                    placeholder={LOCALES[currentLocale].Tell_me_what_you_did_not_like}
                  />
                  <input
                    className="mb-4 
                    w-full md:w-1/2 
                    px-4 border-black 
                    border rounded-md 
                    text-transparent 
                    text-black"
                    type="text"
                    maxLength={maxCustomText}
                    value={thanks}
                    onChange={e => setThanks(e.target.value)}
                    placeholder={LOCALES[currentLocale].Thanks_for_the_feedback}
                  />
                  <input
                    className="mb-4 
                    w-full md:w-1/2 px-4 
                    border-black border 
                    rounded-md text-transparent 
                    text-black"
                    type="text"
                    maxLength={maxCustomText}
                    value={positive}
                    onChange={e => setPositive(e.target.value)}
                    placeholder={LOCALES[currentLocale].Thank_you}
                  />
                </>
              )}
              <label className="text-sm font-medium" htmlFor="sendText">
                  {LOCALES[currentLocale].Provide_a_link_to_redirect_positive_reviews}
                </label>
              <input
                className="mb-4 
                w-full md:w-1/2 px-4 
                border-black border 
                rounded-md text-transparent 
                text-black"
                type="text"
                value={url}
                onChange={e => setUrl(e.target.value)}
                placeholder={LOCALES[currentLocale].Link_to_external_resource_for_feedback}
              />
              </div>
              <button
                type="submit"
                className="group w-80 flex justify-center py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ml-center text-center">
                {LOCALES[currentLocale].ADD_POLL}
            </button>
        {error.length ? (
          <p className="text-red-600 mt-4">{error}</p>
        ) : null}
      </form>
    </div>
  </MainLayout>
);

}

function mapStateToProps(state: IState) {
    return {
        orgData: state.orgData
    }
}

export default connect(mapStateToProps)(AddReviews)
