import React, { useEffect, useState } from 'react'
import './App.scss'
import { connect } from 'react-redux'
import { IState } from './models/IState'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import {
    guestPrivateRoutes,
    guestPublicRoutes,
    guestRegisterRoute,
    privateRoutes,
    publicRoutes
} from './routes'
import {
    GUEST_CARDS,
    GUEST_MAIN,
    GUEST_REGISTER,
    LOGIN_ROUTE,
    MAIN_ROUTE
} from './utils/routesConsts'
import LoadingLayout from './layout/LoadingLayout'
import Loader from './components/Loader'
import { get_locale } from './services/api'

interface AppProps {
    isAdminAuth: boolean
    isGuestAuth: boolean
    isLoading: boolean
}

const App: React.FC<AppProps> = ({ isAdminAuth, isGuestAuth, isLoading }) => {
    const { pathname } = new URL(window.location.href)
    const [isLocaleLoaded, setIsLocaleLoaded] = useState<boolean>(false)
    useEffect(() => {
        if(!localStorage.getItem('currentLocale')){
            get_locale().then(res => {
                let pageTitle = 'Get Wallet'
                switch (res.data?.locale) {
                    // case 'UA':
                    //     localStorage.setItem('currentLocale', 'ua_UA')
                    //     break;
                    case 'RU':
                        localStorage.setItem('currentLocale', 'ru_RU')
                        break;
                    case 'EN':
                        localStorage.setItem('currentLocale', 'en_EN')
                        break;
                    default:
                        localStorage.setItem('currentLocale', 'ru_RU')
                        break;
                }
                if(document != null){
                    document.title = pageTitle;
                }
                setIsLocaleLoaded(true)
            })
        } else {
            setIsLocaleLoaded(true)
        }
        //eslint-disable-next-line
    }, [])
    ///
    if (pathname.includes('/leave_feedback/')) {
        sessionStorage.setItem('path', pathname)
    }

    if (isLoading) {
        return (
            <LoadingLayout>
                <Loader color={'#fff'} />
            </LoadingLayout>
        )
    }
    if(isLocaleLoaded){
        if (pathname.includes('/admin')) {
            return isAdminAuth ? (
                <Switch>
                    {publicRoutes.map(({ path, component }) => (
                        <Route
                            key={path}
                            path={path}
                            component={component}
                            exact={true}
                        />
                    ))}
                    <Redirect to={MAIN_ROUTE} />
                </Switch>
            ) : (
                <Switch>
                    {privateRoutes.map(({ path, component }) => (
                        <Route
                            key={path}
                            path={path}
                            component={component}
                            exact={true}
                        />
                    ))}
                    <Redirect to={LOGIN_ROUTE} />
                </Switch>
            )
        } else {
            return isGuestAuth || localStorage.getItem('isExsistingUser') || (sessionStorage.getItem('path') && localStorage.getItem('phone'))? (
                <Switch>
                    {guestPrivateRoutes.map(({ path, component }) => (
                        <Route
                            key={path}
                            path={path}
                            component={component}
                            exact={true}
                        />
                    ))}
                    <Redirect
                        to={
                            sessionStorage.getItem('path')
                                ? (sessionStorage.getItem('path') as string)
                                : GUEST_CARDS
                        }
                    />
                </Switch>
            ) : localStorage.getItem('guestToken') && !isGuestAuth  ? (
                <Switch>
                    {guestRegisterRoute.map(({ path, component }) => (
                        <Route
                            key={path}
                            path={path}
                            component={component}
                            exact={true}
                        />
                    ))}
                    <Redirect to={GUEST_REGISTER} />
                </Switch>
            ) : (
                <Switch>
                    {guestPublicRoutes.map(({ path, component }) => (
                        <Route
                            key={path}
                            path={path}
                            component={component}
                            exact={true}
                        />
                    ))}
                    <Redirect to={GUEST_MAIN} />
                </Switch>
            )
        }        
    } else {
        return null
    }

}

function mapStateToProps(state: IState) {
    return {
        isAdminAuth: state.isAdminAuth,
        isGuestAuth: state.isGuestAuth,
        isLoading: state.isLoading
    }
}

export default withRouter(connect(mapStateToProps)(App))
