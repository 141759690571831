export const LOGIN_ROUTE = '/admin/login'
export const MAIN_ROUTE = '/admin/main/'
export const MAIN_ROUTE_FEEDBACK ='/admin/main/feedback_statistics'
export const MAIN_ROUTE_CARD ='/admin/main/card_statistics'
export const CLIENTS_ROUTE = '/admin/clients'
export const DESIGN_ROUTE = '/admin/design'
export const DESIGN_ROUTE_PAY = '/admin/design/pay'
export const DESIGN_ROUTE_WALLET = '/admin/design/wallet'
export const DESIGN_ROUTE_SITE = '/admin/design/site'
export const DESIGN_ROUTE_BACK = '/admin/design/back'
export const PUSH_MSG_ROUTE = '/admin/push'
export const PUSH_MSG_TEXT_ROUTE = '/admin/push/text'
export const PUSH_MSG_GEO_ROUTE = '/admin/push/geo'
export const PUSH_MSG_EDIT_ROUTE = '/admin/push/edit'
export const REVIEWS_ROUTE = '/admin/reviews'
export const REVIEWS_ADD_ROUTE = '/admin/reviews/add'
export const REVIEWS_EDIT_ROUTE = '/admin/reviews/edit'
export const REVIEWS_STATS_ROUTE = '/admin/reviews/stats'
export const TRIGGERS_ROUTE = '/admin/triggers'
export const SETTINGS_ROUTE = '/admin/settings'
export const UTM_ROUTE = '/admin/utm'

export const GUEST_MAIN = '/'
export const GUEST_REGISTER = '/register'
export const GUEST_CARDS = '/cards'
export const LEAVE_FEEDBACK_ROUTE = '/leave_feedback/:name'
