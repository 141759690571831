import React, {ReactNode, useEffect} from 'react';

const LoginLayout: React.FC<ReactNode> = ({children}) => {
    useEffect(() => {
    }, [])
    return (
        <div className="bg-gradient w-full vh-100 flex justify-center items-center">
            <div className="main-block xl:rounded-2xl lg:shadow-2xl">
                <div className="h-full w-full flex justify-center items-center login-bg xl:rounded-2xl lg:shadow-2xl">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default LoginLayout;
