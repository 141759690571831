import React, { FormEvent, useEffect, useRef, useState } from "react";
import GuestLayout from "../layout/GuestLayout";
import { ICreateGuest, ISiteDesign } from "../models/IApiResponses";
import { post_create_guest } from "../services/api";
import { connect, useDispatch } from "react-redux";
import {
	addNotification,
	setDownloadURLs,
	setGuestAuth,
	setUserRequestLoading,
} from "../store/actions";
import { IGuestUser, IState } from "../models/IState";
import { v4 } from "uuid";
import ConfirmPhoneModal from "../components/ConfirmPhoneModal";
import Loader from "../components/Loader";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import en from "date-fns/locale/en-US";

import { LOCALES } from "../locales/locales";
import { TLanguages } from "../models/ILocales";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LocaleSelectorHardcoded from "../components/kit/locale-selector-hardcoded/locale-selector-hardcoded";

interface RegesterGuestProps {
	guestUser: IGuestUser;
	isUserRequestLoading: boolean;
	siteDesigns: ISiteDesign | null;
}

const RegisterGuest: React.FC<RegesterGuestProps> = ({
	guestUser,
	isUserRequestLoading,
	siteDesigns,
}) => {
	const dispatch = useDispatch();
	const [phoneDisabled, setPhoneDisabled] = useState(false);
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const [currentLocale, setCurrentLocale] = useState<TLanguages>(
		localStorage.getItem("currentLocale") as TLanguages,
	);

	const [guest, setGuest] = useState<ICreateGuest>({
		name: "",
		second_name: "",
		phone: "",
		email: "",
		gender: 0,
		birthday: "",
		recommender_phone: localStorage.getItem("recommender") || "",
		wants_promo: false,
		privacy_policy: true,
	});
	const refRecomendedPhone = useRef<null | HTMLInputElement>(null);

	const capitalizeFirstLetterName = (event: any) => {
		const capitalizedValue = capitalizeFirstLetter(event.target.value);
		setGuest({ ...guest, name: capitalizedValue });
	};

	const capitalizeFirstLetterSecondName = (event: any) => {
		const capitalizedValue = capitalizeFirstLetter(event.target.value);
		setGuest({ ...guest, second_name: capitalizedValue });
	};

	const capitalizeFirstLetter = (value: any) => {
		return value.charAt(0).toUpperCase() + value.slice(1);
	};

	useEffect(() => {
		let phone = localStorage.getItem("phone");
		if (phone) {
			setGuest({ ...guest, phone });
			setPhoneDisabled(true);
		}
		//eslint-disable-next-line
	}, []);
	useEffect(() => {
		const storageLocale = localStorage.getItem("currentLocale");
		switch (storageLocale) {
			// case 'ua_UA':
			//     setCurrentLocale('ua_UA')
			//     break;
			case "ru_RU":
				setCurrentLocale("ru_RU");
				break;
			case "en_EN":
				setCurrentLocale("en_EN");
				break;
			default:
				setCurrentLocale("ru_RU");
				break;
		}
	}, []);
	useEffect(() => {
		if (openConfirmModal) {
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflowY = "auto";
		}
	}, [openConfirmModal]);
	guest.name = guest.name.replace(/^\s+|\s+$/g, "");
	guest.second_name = guest.second_name.replace(/^\s+|\s+$/g, "");
	guest.email = guest.email.replace(/^\s+|\s+$/g, "");
	guest.recommender_phone =
		guest.recommender_phone?.replace(/^\s+|\s+$/g, "") || "";
	guest.birthday = guest.birthday.replace(/^\s+|\s+$/g, "");

	const handleFormSubmit = (
		e: FormEvent<HTMLFormElement> | undefined,
		skip = false,
	) => {
		if (e) e.preventDefault();
		if (
			(siteDesigns?.fields?.name === 1 && guest.name === "") ||
			(siteDesigns?.fields?.second_name === 1 && guest.second_name === "") ||
			(siteDesigns?.fields?.email === 1 && guest.email === "") ||
			(siteDesigns?.fields?.gender === 1 && guest.gender === 0) ||
			(siteDesigns?.fields?.birthday === 1 && guest.birthday === "") ||
			(siteDesigns?.fields?.recommender_phone === 1 &&
				guest.recommender_phone === "")
		) {
			dispatch(
				addNotification({
					id: v4(),
					type: "Error",
					msg: LOCALES[currentLocale].FILL_OUT_THE_REQUIRED_FIELD,
				}),
			);
			return;
		}
		if (guest.recommender_phone === localStorage.getItem("phone")) {
			dispatch(
				addNotification({
					id: v4(),
					type: "Error",
					msg: LOCALES[currentLocale].RECOMENDER_PHONE_ERROR,
				}),
			);
			return;
		}
		if (guest.recommender_phone?.length && refRecomendedPhone) {
			if (
				!(
					(guest.recommender_phone.indexOf("7") === 0 &&
						guest.recommender_phone?.length === 11) ||
					(guest.recommender_phone.indexOf("380") === 0 &&
						guest.recommender_phone?.length === 12) ||
					(guest.recommender_phone.indexOf("1") === 0 &&
						guest.recommender_phone?.length === 11) ||
					(guest.recommender_phone.indexOf("971") === 0 &&
						guest.recommender_phone?.length > 12 &&
						guest.recommender_phone?.length <= 15)
				)
			) {
				dispatch(
					addNotification({
						id: v4(),
						type: "Error",
						msg: LOCALES[currentLocale].RECOMENDER_PHONE_ERROR,
					}),
				);
				return refRecomendedPhone.current?.focus();
			}
		}
		if (guestUser.use_call_check) {
			if (!skip) return setOpenConfirmModal(true);
		}
		dispatch(setUserRequestLoading(true));
		const query = { ...guest };
		if (query.recommender_phone === "") delete query.recommender_phone;

		const urlParams = new URLSearchParams(window.location.search);
		const utmSource = urlParams.get("utm_source");
		const utmTag = urlParams.get("utm_tag");

		post_create_guest(query, utmSource, utmTag)
			.then((res) => {
				if (res.status === 200 && "download_urls" in res.data) {
					localStorage.setItem("phone", guest.phone);
					localStorage.setItem("isExsistingUser", "true");
					dispatch(setDownloadURLs(res.data.download_urls));
					dispatch(setGuestAuth(true));
				}
			})
			.catch(() => {
				dispatch(
					addNotification({
						id: v4(),
						type: "Error",
						msg: LOCALES[currentLocale].SERVER_ERROR,
					}),
				);
			})
			.finally(() => {
				dispatch(setUserRequestLoading(false));
			});
	};

	const changeNumber = () => {
		dispatch(setGuestAuth(false));
	};

	const handleParentFun = (value: any) => {
		localStorage.setItem("currentLocale", value);
		switch (value) {
			case "ru_RU":
				setCurrentLocale("ru_RU");
				break;
			case "en_EN":
				setCurrentLocale("en_EN");
				break;
			default:
				setCurrentLocale("ru_RU");
				break;
		}
	};

	const handleLocaleChange = (locale: string, localeObject: any) => {
		registerLocale(locale, localeObject);
		setDefaultLocale(locale);
	};

	useEffect(() => {
		switch (currentLocale) {
			case "ru_RU":
				handleLocaleChange("ru", ru);
				break;
			case "en_EN":
				handleLocaleChange("en", en);
				break;
			default:
				handleLocaleChange("ru", ru);
				break;
		}
	}, [currentLocale]);

	return (
		<GuestLayout>
			<div className="flex flex-col w-80" style={{ height: "100%" }}>
				<LocaleSelectorHardcoded
					handleParentFun={(value: any) => {
						handleParentFun(value);
					}}
				></LocaleSelectorHardcoded>
				<div className="flex flex-col gap-3" style={{ margin: "auto" }}>
					{openConfirmModal ? (
						<ConfirmPhoneModal
							closeModal={() => setOpenConfirmModal(false)}
							submit={() => handleFormSubmit(undefined, true)}
							phone={guest.phone}
						/>
					) : null}
					{siteDesigns === null ? (
						<div className="flex items-center justify-center">
							<Loader color={"#4f46e5"} />
						</div>
					) : (
						<form
							onSubmit={(e) => handleFormSubmit(e)}
							className="flex flex-col gap-3 w-80 relative"
						>
							<div className="relative w-full">
								<input
									type="text"
									placeholder={LOCALES[currentLocale].MAIN_NAME}
									className="outline-none transition-all focus:ring-2 focus:ring-indigo-700 py-2 px-4 rounded-md border border-blue-400 w-full h-10"
									style={{
										display: siteDesigns?.fields?.name === 3 ? "none" : "block",
										border:
											siteDesigns?.fields?.name === 1 &&
											guest.name.trim() === ""
												? "2px solid red"
												: "1px solid #ccc",
									}}
									onChange={capitalizeFirstLetterName}
									value={guest.name}
									required={siteDesigns?.fields?.name === 1}
									minLength={2}
									pattern="([А-Я]{1}[а-яё]{1,23}|[A-Z]{1}[a-z]{1,23})$"
									title={
										LOCALES[currentLocale].Numbers_and_symbols_are_not_allowed
									}
								/>
								{siteDesigns?.fields?.name === 1 ? (
									<span className="absolute top-2 right-2 text-red-600 text-2xl">
										*
									</span>
								) : null}
							</div>
							<div className="relative w-full">
								<input
									type="text"
									placeholder={LOCALES[currentLocale].LAST_NAME}
									className="outline-none transition-all focus:ring-2 focus:ring-indigo-700 py-2 px-4 rounded-md border border-blue-400 w-full h-10"
									style={{
										display:
											siteDesigns?.fields?.second_name === 3 ? "none" : "block",
										border:
											siteDesigns?.fields?.second_name === 1 &&
											guest.second_name.trim() === ""
												? "2px solid red"
												: "1px solid #ccc",
									}}
									onChange={capitalizeFirstLetterSecondName}
									value={guest.second_name}
									required={siteDesigns?.fields?.second_name === 1}
									minLength={2}
									pattern="([А-Я]{1}[а-яё]{1,23}|[A-Z]{1}[a-z]{1,23})$"
									title={
										LOCALES[currentLocale].Numbers_and_symbols_are_not_allowed
									}
								/>
								{siteDesigns?.fields?.second_name === 1 ? (
									<span className="absolute top-2 right-2 text-red-600 text-2xl">
										*
									</span>
								) : null}
							</div>
							<div className="relative w-full">
								<PhoneInput
									inputStyle={{
										width: "100%",
										height: "2.5rem",
										paddingTop: "0.5rem",
										paddingBottom: "0.5rem",
									}}
									regions={[
										"eu-union",
										"ex-ussr",
										"america",
										"north-america",
										"south-america",
										"central-america",
										"carribean",
										"ex-yugos",
										"baltic",
										"middle-east",
										"north-africa",
										"asia",
									]}
									value={guest.phone}
									disabled={phoneDisabled}
									inputProps={{
										name: "phone",
										required: true,
									}}
									onChange={(p: any) => {
										if (p) {
											setGuest({
												...guest,
												phone: p,
											});
										}
									}}
								/>
								<span className="absolute top-2 right-2 text-red-600 text-2xl">
									*
								</span>
							</div>
							<div className="relative w-full">
								<input
									type="email"
									placeholder="E-mail"
									className="outline-none transition-all focus:ring-2 focus:ring-indigo-700 py-2 px-4 rounded-md border border-blue-400 w-full h-10"
									style={{
										display:
											siteDesigns?.fields?.email === 3 ? "none" : "block",
										border:
											siteDesigns?.fields?.email === 1 &&
											guest.email.trim() === ""
												? "2px solid red"
												: "1px solid #ccc",
									}}
									onChange={(e) =>
										setGuest({
											...guest,
											email: e.target.value,
										})
									}
									value={guest.email}
									required={siteDesigns?.fields?.email === 1}
								/>
								{siteDesigns?.fields?.email === 1 ? (
									<span className="absolute top-2 right-2 text-red-600 text-2xl">
										*
									</span>
								) : null}
							</div>
							<div className="relative w-full select-without-arrow">
								<select
									name="gender"
									placeholder={LOCALES[currentLocale].SEX}
									className={`${
										guest.gender === 0 ? "text-gray-400 text-opacity-90" : ""
									} outline-none transition-all focus:ring-2 focus:ring-indigo-700 py-2 px-3 rounded-md border border-blue-400 w-full h-10`}
									style={{
										display:
											siteDesigns?.fields?.gender === 3 ? "none" : "block",
										border:
											siteDesigns?.fields?.gender === 1 && guest.gender === 0
												? "2px solid red"
												: "1px solid #ccc",
									}}
									value={guest.gender}
									onChange={(e) =>
										setGuest({
											...guest,
											gender: parseInt(e.target.value),
										})
									}
									required={siteDesigns?.fields?.gender === 1}
								>
									<option value={0} disabled>
										{LOCALES[currentLocale].SEX}
									</option>
									<option value={1}>{LOCALES[currentLocale].SEX_MALE}</option>
									<option value={2}>{LOCALES[currentLocale].SEX_FEMALE}</option>
								</select>
								{siteDesigns?.fields?.gender === 1 ? (
									<span className="absolute top-2 right-2 text-red-600 text-2xl">
										*
									</span>
								) : null}
							</div>
							<div
								className="flex items-center bg-white rounded-md border border-grey-400"
								style={{
									display:
										siteDesigns?.fields?.birthday === 3 ? "none" : "block",
								}}
							>
								<span className="ml-2">{LOCALES[currentLocale].BIRTHDATE}</span>
								<div
									className="flex items-center relative rounded-md"
									style={{
										display:
											siteDesigns?.fields?.birthday === 3 ? "none" : "block",
										border:
											siteDesigns?.fields?.birthday === 1 &&
											guest.birthday.trim() === ""
												? "2px solid red"
												: "1px solid #ccc",
									}}
								>
									<DatePicker
										selected={guest.birthday ? new Date(guest.birthday) : null}
										onChange={(date) => {
											setGuest({
												...guest,
												birthday: date ? date.toISOString().split("T")[0] : "",
											});
										}}
										onBlur={(e) => {
											const selectedDate = guest.birthday
												? new Date(guest.birthday)
												: null;
											const minimalDate = new Date("1920-01-01");
											const today = new Date();
											const selectedDateFormatted = selectedDate
												? selectedDate.toISOString().split("T")[0]
												: "";
											const minimalDateFormatted = minimalDate
												.toISOString()
												.split("T")[0];
											const todayFormatted = today.toISOString().split("T")[0];

											if (
												!selectedDate ||
												selectedDateFormatted < minimalDateFormatted ||
												selectedDateFormatted >= todayFormatted
											) {
												setGuest({
													...guest,
													birthday: "",
												});
												dispatch(
													addNotification({
														id: v4(),
														type: "Error",
														msg: LOCALES[currentLocale].DATE_ERROR,
													}),
												);
											}
										}}
										dateFormat="dd.MM.yyyy"
										maxDate={new Date()}
										className="outline-none text-md focus:ring-2 focus:ring-indigo-700 py-2 px-3 rounded-md border border-grey-400 h-9 w-full"
									/>
									{siteDesigns?.fields?.birthday === 1 ? (
										<span className="absolute top-2 right-1 text-red-600 text-2xl">
											*
										</span>
									) : null}
								</div>
							</div>
							<div
								className="flex items-center bg-white rounded-md border border-grey-400"
								style={{
									display:
										siteDesigns?.fields?.recommender_phone === 3
											? "none"
											: "block",
								}}
							>
								<span className="ml-2">
									{siteDesigns?.recommender_text ||
										LOCALES[currentLocale].RECOMENDER_PHONE}
								</span>

								<div
									className="relative w-full rounded-mdgit"
									style={{
										display:
											siteDesigns?.fields?.recommender_phone === 3
												? "none"
												: "block",
										border:
											siteDesigns?.fields?.recommender_phone === 1 &&
											guest.recommender_phone?.trim() === ""
												? "2px solid red"
												: "1px solid #ccc",
									}}
								>
									<PhoneInput
										inputStyle={{
											width: "100%",
											height: "2.5rem",
											paddingTop: "0.5rem",
											paddingBottom: "0.5rem",
										}}
										country={"ru"}
										regions={[
											"eu-union",
											"ex-ussr",
											"america",
											"north-america",
											"south-america",
											"central-america",
											"carribean",
											"ex-yugos",
											"baltic",
											"middle-east",
											"north-africa",
											"asia",
										]}
										value={guest.recommender_phone || null}
										placeholder={
											siteDesigns?.recommender_text ||
											LOCALES[currentLocale].RECOMENDER_PHONE
										}
										inputProps={{
											name: "phone",
											required:
												siteDesigns?.fields?.recommender_phone === 1 ||
												!!guest.recommender_phone?.length,
										}}
										onChange={(p: any) => {
											if (p) {
												setGuest({
													...guest,
													recommender_phone: p,
												});
											} else {
												setGuest({
													...guest,
													recommender_phone: "",
												});
											}
										}}
									/>
									{siteDesigns?.fields?.recommender_phone === 1 ? (
										<span className="absolute top-2 right-2 text-red-600 text-2xl">
											*
										</span>
									) : null}
								</div>
							</div>
							<div className="flex flex-row items-center gap-2 justify-center">
								<input
									id="mailing"
									type="checkbox"
									placeholder="E-mail"
									className="outline-none transition-all focus:ring-2 focus:ring-indigo-700 py-2 px-4 rounded-md border border-blue-400"
									onChange={() =>
										setGuest({
											...guest,
											wants_promo: !guest.wants_promo,
										})
									}
									defaultChecked={false}
								/>
								<label
									htmlFor="mailing"
									className="text-white font-bold "
									style={{
										textShadow: siteDesigns?.text_shadow
											? "1px 1px 2px black, 0em 0em 6em #000000"
											: "none",
									}}
								>
									{LOCALES[currentLocale].RECIVE_NEWSLETTER}
								</label>
							</div>
							<div className="flex flex-row items-center gap-2 justify-center">
								<input
									id="privacy_policy"
									type="checkbox"
									className="outline-none transition-all focus:ring-2 focus:ring-indigo-700 py-2 px-4 rounded-md border border-blue-400"
									onChange={() =>
										setGuest({
											...guest,
											privacy_policy: !guest.privacy_policy,
										})
									}
									defaultChecked={true}
								/>
								<label
									htmlFor="privacy_policy"
									className="text-white font-bold"
									style={{
										textShadow: siteDesigns?.text_shadow
											? "1px 1px 2px black, 0em 0em 6em #000000"
											: "none",
									}}
								>
									{LOCALES[currentLocale].AGREE_WITH + " "}
									<a
										href={guestUser?.privacy_policy_link}
										style={{
											color: guestUser?.privacy_policy_link
												? "#736bff"
												: "inherit",
										}}
									>
										{LOCALES[currentLocale].CONDITIONS}
									</a>
								</label>
							</div>

							<button
								disabled={isUserRequestLoading || !guest.privacy_policy}
								type="submit"
								className="btn-request-init group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								style={{
									backgroundColor:
										(siteDesigns && siteDesigns.registration_button_color) ||
										"#4f46e5",
								}}
							>
								{isUserRequestLoading && !guest.privacy_policy ? (
									<div className="lds-mini-ring">
										<div />
										<div />
										<div />
										<div />
									</div>
								) : (
									LOCALES[currentLocale].REGISTER
								)}
							</button>
						</form>
					)}
					{siteDesigns !== null ? (
						<button
							className="btn-request-init group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							onClick={() => changeNumber()}
							style={{
								backgroundColor:
									(siteDesigns && siteDesigns.change_phone_button_color) ||
									"#dc2626",
							}}
						>
							{LOCALES[currentLocale].CHANGE_PHONE_NUMBER}
						</button>
					) : null}
				</div>
			</div>
		</GuestLayout>
	);
};

function mapStateToProps(state: IState) {
	return {
		guestUser: state.guestUser,
		isUserRequestLoading: state.isUserRequestLoading,
		siteDesigns: state.siteDesigns,
	};
}

export default connect(mapStateToProps)(RegisterGuest);
