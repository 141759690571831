import MainLayout from '../layout/MainLayout';

const Settings = () => {
    return (
        <MainLayout>
            Settings
        </MainLayout>
    );
};

export default Settings;
