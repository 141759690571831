import React from 'react'
import MainLayout from '../../layout/MainLayout'

const BackDesign = () => {
    return (
        <MainLayout>
            <div>BackDesign</div>
        </MainLayout>
    )
}

export default BackDesign
