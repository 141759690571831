import React, { FormEvent, useEffect, useState } from 'react'
import MainLayout from '../../layout/MainLayout'
import { connect, useDispatch } from 'react-redux'
import { ICreateLocation } from '../../models/IApiResponses'
import { IState, OrgData } from '../../models/IState'
import {
    create_locations,
    get_location_by_id,
    patch_locations
} from '../../services/api'
import { Redirect, useLocation } from 'react-router-dom'
import { addNotification } from '../../store/actions'
import { v4 } from 'uuid'
import { LOCALES } from '../../locales/locales'
import { TLanguages } from '../../models/ILocales'

interface AddGeoPushProps {
    orgData: OrgData
}

const GeoPush: React.FC<AddGeoPushProps> = ({ orgData }) => {
    const dispatch = useDispatch()
    const query = useQuery()
    const [error, setError] = useState('')
    const [redirect, setRedirect] = useState(false)
    const [editPush, setEditPush] = useState(false)
    const [currentLocale] = useState<TLanguages>(localStorage.getItem('currentLocale') as TLanguages)
    const [data, setData] = useState<ICreateLocation>({
        entity: '',
        latitude: 0,
        longitude: 0,
        text: '',
        org: orgData.orgId
    })
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(
            addNotification({
                id: v4(),
                type: 'Info',
                msg: LOCALES[currentLocale].ANDROID_PLATFORM_GEO_PUSH_WARNING
            })
        )
        if (query.get('id')) {
            get_location_by_id(parseInt(query.get('id') || '0'))
                .then(res => {
                    setData(res.data)
                })
                .catch(e => {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Error',
                            msg: e.response.data.detail
                        })
                    )
                })
            setEditPush(true)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setError('')
    }, [data])

    function useQuery() {
        const { search } = useLocation()
        return React.useMemo(() => new URLSearchParams(search), [search])
    }

    const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        if (!data.entity.trim().length) {
            dispatch(
                addNotification({
                    id: v4(),
                    type: 'Error',
                    msg: LOCALES[currentLocale].FIELDS_ARE_NOT_FILLED
                })
            )
            return setError(LOCALES[currentLocale].FIELDS_ARE_NOT_FILLED)
        }
        if (editPush && query.get('id')) {
            patch_locations(parseInt(query.get('id') || '0'), data)
                .then(res => {
                    if (res.status === 200) {
                        dispatch(
                            addNotification({
                                id: v4(),
                                type: 'Success',
                                // msg: `Пуш на адрес - '${res.data.entity}' изменен.`
                                msg: res.data.entity + ' - ' + LOCALES[currentLocale].PUSH_CHANGE_SUCCESS// need to find and use apropriate string.format package
                            })
                        )
                        setRedirect(true);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setError(LOCALES[currentLocale].SERVER_ERROR)
                        dispatch(
                            addNotification({
                                id: v4(),
                                type: 'Error',
                                msg: LOCALES[currentLocale].SERVER_ERROR
                            })
                        )
                    }
                })
                .catch(e => {
                    setLoading(false);
                    setError(LOCALES[currentLocale].SERVER_ERROR)
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Error',
                            msg: e.response.data.detail
                        })
                    )
                })
        } else {
            create_locations(data)
                .then(res => {
                    if (res.status === 201) {
                        dispatch(
                            addNotification({
                                id: v4(),
                                type: 'Success',
                                // msg: `Пуш на адрес - '${res.data.entity}' создан.`
                                msg: res.data.entity + ' - ' + LOCALES[currentLocale].PUSH_CREATION_SUCCESS// need to find and use apropriate string.format package
                            })
                        )
                        setRedirect(true);
                        setLoading(false);
                    } else {
                        setError(LOCALES[currentLocale].SERVER_ERROR)
                        setLoading(false);
                        dispatch(
                            addNotification({
                                id: v4(),
                                type: 'Error',
                                msg: LOCALES[currentLocale].SERVER_ERROR
                            })
                        )
                    }
                })
                .catch(e => {
                    setError(LOCALES[currentLocale].SERVER_ERROR)
                    setLoading(false);
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Error',
                            msg: e.response.data.detail
                        })
                    )
                })
        }
    }

    if (redirect) {
        return <Redirect to={`/admin/push${editPush ? '/edit' : ''}`} />
    }

    return (
        <MainLayout>
            <div>
                <form
                    onSubmit={e => handleFormSubmit(e)}
                    className="w-full flex flex-col gap-2 items-center"
                >
                    <input
                        type="text"
                        placeholder="Адрес"
                        value={data.entity}
                        onChange={e =>
                            setData({
                                ...data,
                                entity: e.target.value
                            })
                        }
                        className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                        required
                    />
                    <div className="flex flex-row gap-5 w-full">
                        <span>{LOCALES[currentLocale].LATITUDE}</span>
                        <input
                            type="number"
                            min={-90}
                            max={90}
                            step={0.000001}
                            pattern="^\d+(?:\.\d{1,2,3,4,5,6})?$"
                            placeholder={LOCALES[currentLocale].LATITUDE}
                            value={data.latitude}
                            onChange={e =>
                                setData({
                                    ...data,
                                    latitude: parseFloat(e.target.value)
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                            required
                        />
                    </div>
                    <div className="flex flex-row gap-5 w-full">
                        <span>{LOCALES[currentLocale].LONGITUDE}</span>
                        <input
                            type="number"
                            min={-180}
                            max={180}
                            step={0.000001}
                            pattern="^\d+(?:\.\d{1,2,3,4,5,6})?$"
                            placeholder={LOCALES[currentLocale].LONGITUDE}
                            value={data.longitude}
                            onChange={e =>
                                setData({
                                    ...data,
                                    longitude: parseFloat(e.target.value)
                                })
                            }
                            className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                            required
                        />
                    </div>

                    <textarea
                        cols={30}
                        rows={5}
                        placeholder={LOCALES[currentLocale].ENTER_PUSH_TEXT}
                        value={data.text}
                        onChange={e =>
                            setData({
                                ...data,
                                text: e.target.value
                            })
                        }
                        className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full resize-none"
                    />
                    <button
                        type="submit"
                        disabled={loading}
                        className="group relative w-80 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        {editPush ? LOCALES[currentLocale].CHANGE_GEO_PUSH : LOCALES[currentLocale].CREATE_GEO_PUSH}
                    </button>
                    {error.length ? (
                        <p className="text-red-600">{error}</p>
                    ) : null}
                </form>
            </div>
        </MainLayout>
    )
}

function mapStateToProps(state: IState) {
    return {
        orgData: state.orgData
    }
}

export default connect(mapStateToProps)(GeoPush)
