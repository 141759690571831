import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import GuestLayout from '../layout/GuestLayout'
import { IGuestUser, IState } from '../models/IState'
import { connect } from 'react-redux'
import { feedback_available, post_customer_feedbacks } from '../services/api'
import { IFeedbackAvailable } from '../models/IApiResponses'
import { LOCALES } from '../locales/locales'
import { TLanguages } from '../models/ILocales'
import { useHistory } from 'react-router-dom';

interface LeaveFeedbackProps {
    guestUser: IGuestUser
}

const LeaveFeedback: React.FC<LeaveFeedbackProps> = ({ guestUser }) => {
    const { params } = useRouteMatch()
    const [data, setData] = useState<IFeedbackAvailable>()
    const [loading, setLoading] = useState(true)
    const [available, setAvailable] = useState(false)
    const [score, setScore] = useState(0)
    const [created, setCreated] = useState(false)
    const [currentLocale] = useState<TLanguages>(
        localStorage.getItem('currentLocale') as TLanguages
    )
    const [feedbackText, setFeedbackText] = useState<string>('')
    const [showSubmitButton, setShowSubmitButton] = useState(true)
    const [showGoButton, setShowGoButton] = useState(false)

    // @ts-ignore
    const name: string = params['name']

    const history = useHistory();
    const handleButtonClick = () => {
        history.push('/cards');
    };

    useEffect(() => {
        feedback_available(guestUser.id, name)
            .then(res => {
                setData(res.data)
                setAvailable(true)
            })
            .catch(() => {
                setAvailable(false)
            })
            .finally(() => {
                setLoading(false)
            })
        // eslint-disable-next-line
    }, [])

    const handleScoreChange = (e: any) => {
        setScore(parseInt(e.target.value))
    }

    const handleSubmit = () => {
        post_customer_feedbacks({
            customer: guestUser.id,
            score,
            feedback: data?.data.id ?? 0,
            org: guestUser.org,
            text: feedbackText // Добавляем текст отзыва
        }).then(e => {
            if (e.status === 201) {
                setCreated(true)
            }
        })
    }

    const handleSiteFeedbackSubmit = (data: IFeedbackAvailable | undefined) => {
        if (score >= 4 && score <= 5 && data?.data.external_feedback_url) {
            window.location.href = data.data.external_feedback_url
        } else {
            handleSubmit()
        }
    }

    return (
        <GuestLayout>
            <>
                {!loading && available && !created ? (
                    <div className="text-white text-center h-40 flex flex-col items-center">
                        <h3
                            className="text-2xl font-bold text-shadow"
                            style={{ color: guestUser?.title_color }}
                        >
                            {data?.data.title}
                        </h3>
                        <h5
                            className="text-lg font-bold max-w-2xl text-shadow"
                            style={{ color: guestUser?.title_color }}
                        >
                            {data?.data.text}
                        </h5>
                        <div
                            className="flex flex-row gap-5 mt-2"
                            onChange={handleScoreChange}
                        >
                            <div className="score">
                                <input
                                    type="radio"
                                    name="score"
                                    id="1"
                                    value={1}
                                />
                                <label
                                    htmlFor="1"
                                    className="bg-red-700 flex items-center justify-center text-2xl"
                                    style={{
                                        textShadow:
                                            '1px 1px 2px black, 0em 0em 6em #000000'
                                    }}
                                >
                                    1
                                </label>
                            </div>
                            <div className="score">
                                <input
                                    type="radio"
                                    name="score"
                                    id="2"
                                    value={2}
                                />
                                <label
                                    htmlFor="2"
                                    className="bg-yellow-700 flex items-center justify-center text-2xl"
                                    style={{
                                        textShadow:
                                            '1px 1px 2px black, 0em 0em 6em #000000'
                                    }}
                                >
                                    2
                                </label>
                            </div>
                            <div className="score">
                                <input
                                    type="radio"
                                    name="score"
                                    id="3"
                                    value={3}
                                />
                                <label
                                    htmlFor="3"
                                    className="bg-yellow-200 flex items-center justify-center text-2xl"
                                    style={{
                                        textShadow:
                                            '1px 1px 2px black, 0em 0em 6em #000000'
                                    }}
                                >
                                    3
                                </label>
                            </div>
                            <div className="score">
                                <input
                                    type="radio"
                                    name="score"
                                    id="4"
                                    value={4}
                                />
                                <label
                                    htmlFor="4"
                                    className="bg-green-200 flex items-center justify-center text-2xl"
                                    style={{
                                        textShadow:
                                            '1px 1px 2px black, 0em 0em 6em #000000'
                                    }}
                                >
                                    4
                                </label>
                            </div>
                            <div className="score">
                                <input
                                    type="radio"
                                    name="score"
                                    id="5"
                                    value={5}
                                />
                                <label
                                    htmlFor="5"
                                    className="bg-green-500 flex items-center justify-center text-2xl"
                                    style={{
                                        textShadow:
                                            '1px 1px 2px black, 0em 0em 6em #000000'
                                    }}
                                >
                                    5
                                </label>
                            </div>
                        </div>
                        {score >= 1 && score <= 3 && !created && (
                            <div className="mt-5 flex flex-col items-center">
                                <p
                                    className="text-white text-center py-3 text-shadow"
                                    style={{ color: guestUser?.title_color }}
                                >
                                    {data?.data.negative_field}
                                </p>
                                <textarea
                                    value={feedbackText}
                                    onChange={e =>
                                        setFeedbackText(e.target.value)
                                    }
                                    className="border rounded p-4 text-black"
                                    placeholder="..."
                                    rows={4}
                                    cols={30}
                                />
                                <button
                                    onClick={handleSubmit}
                                    className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-3"
                                    style={{
                                        textShadow:
                                            '1px 1px 2px black, 0em 0em 6em #000000'
                                    }}
                                >
                                    {LOCALES[currentLocale].LEAVE_REVIEW}
                                </button>
                            </div>
                        )}
                        {score >= 4 && score <= 5 && (
                            <>
                                {!data?.data.external_feedback_url ? (
                                    <div className="mt-5 flex flex-col items-center">
                                        <button
                                            onClick={() => {
                                                setShowSubmitButton(false)
                                                setShowGoButton(true)
                                                handleSubmit()
                                            }}
                                            className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-3"
                                            style={{
                                                textShadow:
                                                    '1px 1px 2px black, 0em 0em 6em #000000'
                                            }}
                                        >
                                            {
                                                LOCALES[currentLocale]
                                                    .Submit_an_estimate
                                            }
                                        </button>
                                    </div>
                                ) : null}
                                {data?.data.external_feedback_url ? (
                                    <div className="mt-5 flex flex-col items-center">
                                        {showSubmitButton && (
                                            <button
                                                onClick={() => {
                                                    setShowSubmitButton(false)
                                                    setShowGoButton(true)
                                                    handleSubmit()
                                                }}
                                                className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-3"
                                                style={{
                                                    textShadow:
                                                        '1px 1px 2px black, 0em 0em 6em #000000'
                                                }}
                                            >
                                                {
                                                    LOCALES[currentLocale]
                                                        .Submit_an_estimate
                                                }{' '}
                                            </button>
                                        )}
                                    </div>
                                ) : null}
                            </>
                        )}
                    </div>
                ) : null}
                {!loading && !available && !created ? (
                    <div className="text-white text-center">
                        <h3
                            className="text-2xl font-bold"
                            style={{
                                textShadow:
                                    '1px 1px 2px black, 0em 0em 6em #000000'
                            }}
                        >
                            {LOCALES[currentLocale].SURVEY_HAS_ALREADY_PASSED}
                        </h3>
                    </div>
                ) : null}
                {!loading && created && !showGoButton ? (
                    <div className="text-white text-center">
                        <h3
                            className="text-2xl font-bold"
                            style={{
                                textShadow:
                                    '1px 1px 2px black, 0em 0em 6em #000000'
                            }}
                        >
                            {LOCALES[currentLocale].THANKS_FOR_THE_FEEDBACK}
                        </h3>
                    </div>
                ) : null}
                {!loading &&
                created &&
                showGoButton &&
                data?.data.external_feedback_url ? (
                    <div className="mt-5 flex flex-col items-center">
                        <p className="text-2xl text-white text-center text-shadow">
                            {data?.data.positive_field}
                        </p>
                        <button
                            onClick={() => handleSiteFeedbackSubmit(data)}
                            className="group relative flex items-center justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-2"
                            style={{
                                textShadow:
                                    '1px 1px 2px black, 0em 0em 6em #000000'
                            }}
                        >
                            {LOCALES[currentLocale].Go}
                        </button>
                    </div>
                ) : null}
                {!loading && created && !data?.data.external_feedback_url && (score === 4 || score === 5) && (
                <div className="text-white text-center">
                    <h3
                        className="text-2xl font-bold"
                        style={{
                            textShadow:
                                '1px 1px 2px black, 0em 0em 6em #000000'
                        }}
                    >
                        {LOCALES[currentLocale].THANKS_FOR_THE_FEEDBACK}
                    </h3>
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
                        style={{
                            textShadow:
                                '1px 1px 2px black, 0em 0em 6em #000000'
                        }}
                        onClick={handleButtonClick} 
                    >
                        {LOCALES[currentLocale].LOYAITY_PROGRAM}
                    </button>
                </div>
            )}
            </>
        </GuestLayout>
    )
}

function mapStateToProps(state: IState) {
    return {
        guestUser: state.guestUser
    }
}

export default connect(mapStateToProps)(LeaveFeedback)
