import React, { useState } from 'react'
import MainLayout from '../layout/MainLayout'
import CardSVG from '../assets/card.svg'
import { NavLink } from 'react-router-dom'
import { LOCALES } from '../locales/locales'
import { TLanguages } from '../models/ILocales'

interface DesignPanels {
    name: string
    link: string
    logo: string
}




const Design = () => {
    const [currentLocale] = useState<TLanguages>(localStorage.getItem('currentLocale') as TLanguages)
    const [designPanels] = useState<DesignPanels[]>([
        {
            name: LOCALES[currentLocale].GOOGLEPAY_CARD_DESIGN,
            link: '/admin/design/pay',
            logo: CardSVG
        },
        {
            name: LOCALES[currentLocale].APPLEWALLET_CARD_DESIGN,
            link: '/admin/design/wallet',
            logo: CardSVG
        },
        {
            name: LOCALES[currentLocale].REGISTRATION_PAGE_DESIGN,
            link: '/admin/design/site',
            logo: CardSVG
        },
        // {
        //     name: LOCALES[currentLocale].CARD_BACK_SIDE_DESIGN,
        //     link: '/admin/design/back',
        //     logo: CardSVG
        // }
    ])

    return (
        <MainLayout>
            <div className="w-full h-full flex flex-row flex-wrap justify-center gap-3">
                {designPanels.map(item => (
                    <NavLink
                        to={item.link}
                        className="flex flex-col w-80 h-32 p-5 border-blue-500 border rounded-2xl"
                        key={item.name}
                    >
                        <div className="flex items-start gap-3">
                            <img
                                src={item.logo}
                                alt={item.name}
                                className="w-7 h-7 svg-blue"
                            />
                            <span>{item.name}</span>
                        </div>
                    </NavLink>
                ))}
            </div>
        </MainLayout>
    )
}

export default Design
