import React from 'react';

interface LoaderProps {
    color: string
}

const Loader: React.FC<LoaderProps> = ({color}) => {
    return (
        <div className="lds-ring">
            <div style={{ borderColor: `${color} transparent transparent transparent`}}/>
            <div style={{ borderColor: `${color} transparent transparent transparent`}}/>
            <div style={{ borderColor: `${color} transparent transparent transparent`}}/>
            <div style={{ borderColor: `${color} transparent transparent transparent`}}/>
        </div>
    );
};

export default Loader;
