import axios from 'axios'

const { hostname, protocol, searchParams} = new URL(window.location.href)
const phoneNumber = searchParams.get('recommender');

export let API_URL = `https://wallet-stage.open-s.info/api`
// export let API_URL = `https://testsun.wallet.open-s.info/api`

if (hostname !== 'localhost') {
    API_URL = `${protocol}//${hostname}/api`
}

const $api = axios.create({
    baseURL: API_URL
})
if (phoneNumber) {
    localStorage.setItem('recommender',phoneNumber);
} else {
    localStorage.removeItem('recommender');
}

$api.interceptors.request.use(config => {
    const { pathname } = new URL(window.location.href)
    if (pathname.includes('/admin')) {
        if (localStorage.getItem('adminToken'))
            // @ts-ignore
            config.headers.Authorization = `Bearer ${localStorage.getItem(
                'adminToken'
            )}`
    } else {
        if (localStorage.getItem('guestToken'))
            // @ts-ignore
            config.headers.PhoneToken = `Bearer ${localStorage.getItem(
                'guestToken'
            )}`
    }
    return config
})

$api.interceptors.response.use(
    config => {
        return config
    },
    async error => {
        const { pathname } = new URL(window.location.href)
        const originalRequest = error.config
        if (
            error?.response?.status === 401 &&
            error?.config &&
            !error?.config?._isRetry &&
            pathname.includes('/admin')
        ) {
            localStorage.removeItem('token')
            originalRequest._isRetry = true
            try {
                const response = await axios.post(`${API_URL}/token/refresh/`, {
                    refresh: localStorage.getItem('refresh')
                })
                // @ts-ignore
                localStorage.setItem('adminToken', response.data['access'])
                return $api.request(originalRequest)
            } catch (e) {
                localStorage.removeItem('refresh')
                localStorage.removeItem('adminToken')
            }
        }
        throw error
    }
)

export default $api
