import React, { FormEvent, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Redirect } from 'react-router'
import { v4 } from 'uuid'
import WalCheckbox from '../../components/kit/checkbox/WalCheckbox'
import MainLayout from '../../layout/MainLayout'
import { LOCALES } from '../../locales/locales'
import { ISiteDesign } from '../../models/IApiResponses'
import { TLanguages } from '../../models/ILocales'
import { IState, OrgData } from '../../models/IState'
import {
    get_site_designs,
    patch_site_designs,
    post_site_designs
} from '../../services/api'
import { addNotification } from '../../store/actions'

interface SiteDesignProps {
    orgData: OrgData
    siteDesigns: ISiteDesign | null
}

const SiteDesign: React.FC<SiteDesignProps> = ({ orgData, siteDesigns }) => {
    const dispatch = useDispatch()
    const [files, setFiles] = useState({})
    const [redirect, setRedirect] = useState(false)
    const [data, setData] = useState<ISiteDesign>({
        ...(siteDesigns as ISiteDesign)
    })
    const [newPay, setNewPay] = useState(false)
    const [currentLocale] = useState<TLanguages>(localStorage.getItem('currentLocale') as TLanguages)
    useEffect(() => {
        getSiteDesigns()
        // eslint-disable-next-line
    }, [])

    const fileChange = (e: any, field: string) => {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onloadend = () => {
            setData({ ...data, [`${field}`]: reader.result })
            setFiles({ ...files, [`${field}`]: file })
            const formData = new FormData()
            formData.append('logo', file)
            console.log(formData.getAll('logo'))
        }
    }

    const getSiteDesigns = () => {
        get_site_designs()
            .then(res => {
                if (res?.data[0]) {
                    setData(res.data[0])
                } else {
                    setNewPay(true)
                }
            })
            .catch(e => {
                dispatch(
                    addNotification({
                        id: v4(),
                        type: 'Error',
                        msg: e.response.data.detail
                    })
                )
            })
    }

    const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (newPay) {
            post_site_designs(data.id, files, data)
                .then(() => {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Success',
                            msg: LOCALES[currentLocale].DESIGN_CREATION_SUCCESS
                        })
                    )
                    setRedirect(true)
                })
                .catch(e => {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Error',
                            msg: e?.response?.data?.detail
                        })
                    )
                })
        } else {
            patch_site_designs(data.id, files, data)
                .then(() => {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Success',
                            msg: LOCALES[currentLocale].DESIGN_UPDATING_SUCCESS
                        })
                    )
                    setRedirect(true)
                })
                .catch(e => {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Error',
                            msg: e?.response?.data?.detail
                        })
                    )
                })
        }
    }

    if (redirect) {
        return <Redirect to="/admin/design" />
    }

    return (
        <MainLayout>
            <div>
                <div className="flex flex-col items-center mb-5">
                    <span className="text-lg font-bold">{LOCALES[currentLocale].PREVIEW}</span>
                    <div className="site w-full flex flex-row flex-wrap gap-3 justify-center">
                        <div
                            className="border w-60 h-32"
                            style={{
                                backgroundImage: `url('${data.background}')`
                            }}
                        />
                        <div
                            className="border w-20 h-32"
                            style={{
                                backgroundImage: `url('${data.background_mobile}')`
                            }}
                        />
                    </div>
                </div>
                <form
                    onSubmit={e => handleFormSubmit(e)}
                    className="w-full flex flex-col gap-4 items-center"
                >
                    <div className="flex flex-row gap-5 w-full">
                        <label htmlFor="background" className="w-80">
                        {LOCALES[currentLocale].BACKGROUND}
                        </label>
                        <div className="flex flex-col w-full">
                            <span
                                // @ts-ignore
                                hidden={
                                    !data?.background?.length ||
                                    files?.hasOwnProperty('background')
                                }
                                className="text-sm"
                            >
                                {LOCALES[currentLocale].NOW}{' '}
                                <a
                                    href={data.background}
                                    className="text-blue-500"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data?.background?.split('/').pop()}
                                </a>
                            </span>
                            <input
                                type="file"
                                id="background"
                                placeholder={LOCALES[currentLocale].BACKGROUND}
                                onChange={e => fileChange(e, 'background')}
                                className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                            />
                        </div>
                    </div>
                    <div className="flex flex-row gap-5 w-full">
                        <label htmlFor="background_mobile" className="w-80">
                        {LOCALES[currentLocale].BACKGROUND_MOBILE}
                        </label>
                        <div className="flex flex-col w-full">
                            <span
                                // @ts-ignore
                                hidden={
                                    !data?.background_mobile?.length ||
                                    files?.hasOwnProperty('background_mobile')
                                }
                                className="text-sm"
                            >
                                {LOCALES[currentLocale].NOW}{' '}
                                <a
                                    href={data.background_mobile}
                                    className="text-blue-500"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data?.background_mobile?.split('/').pop()}
                                </a>
                            </span>
                            <input
                                type="file"
                                id="background_mobile"
                                placeholder={LOCALES[currentLocale].BACKGROUND_MOBILE}
                                onChange={e =>
                                    fileChange(e, 'background_mobile')
                                }
                                className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                            />
                        </div>
                    </div>
                    <div className="flex flex-row gap-5 w-full">
                        <label htmlFor="background_mobile" className="w-80">
                            {LOCALES[currentLocale].HEADER}
                        </label>
                        <div className="flex flex-col w-full">
                            <input
                                required
                                type="text"
                                id="title"
                                placeholder={LOCALES[currentLocale].FORM_HEADER}
                                value={data.title}
                                onChange={e => {
                                    setData({
                                        ...data,
                                        title: e.target.value
                                    })
                                }}
                                className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                            />
                        </div>
                    </div>
                    <div className="flex flex-row gap-5 w-full">
                        <label htmlFor="background_mobile" className="w-80">
                            {LOCALES[currentLocale].TEXT_COLOR}
                        </label>
                        <div className="flex flex-col w-full">
                            <input
                                required
                                type="text"
                                id="title"
                                placeholder={LOCALES[currentLocale].TEXT_COLOR}
                                value={data.title_color}
                                onChange={e => {
                                    setData({
                                        ...data,
                                        title_color: e.target.value
                                    })
                                }}
                                className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all w-full"
                            />
                        </div>
                    </div>
                    <div className="flex flex-row gap-5 w-full">
                        <label htmlFor="background_mobile" className="w-80">
                            {LOCALES[currentLocale].DISPLAY_ORGANIZATION_NAME}
                        </label>
                        <div className="flex flex-row flex-start w-full">
                            <WalCheckbox
                                defaultState={data.is_show_name}
                                value={data.is_show_name}
                                id="show_balance"
                                onChange={(state: any) => {
                                    setData({
                                        ...data,
                                        is_show_name: !data.is_show_name
                                    })
                                }}
                                className="outline-none border-b border-gray-500 focus:border-blue-500 transition-all justify-items-start"
                            ></WalCheckbox>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="group relative w-80 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 transition-all shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        {LOCALES[currentLocale].CHANGE_SUBMIT_BUTTON}
                    </button>
                </form>
            </div>
        </MainLayout>
    )
}

function mapStateToProps(state: IState) {
    return {
        orgData: state.orgData,
        siteDesigns: state.siteDesigns
    }
}

export default connect(mapStateToProps)(SiteDesign)
