import React, { useState } from "react";
import classes from './WalCheckbox.module.scss';

const WalCheckbox: React.FC<any> = ( { value, id, onChange} ) => {

    const [isChecked, setIsCheckedState] = useState(value)

    const toggleCheck = () => {
        setIsCheckedState(!isChecked)
        onChange(!isChecked)
    }

    return (
        <span className={classes.customCheckbox} onClick={toggleCheck}>
            <input className={classes.inputField} checked={value} id={id} type="checkbox" onChange={(e)=>{}}/> 
            <span></span>
        </span>
    );
};

export default WalCheckbox;
