import React, { useState } from 'react'
import MainLayout from '../layout/MainLayout'
import ReviewSVG from '../assets/review.svg'
import { NavLink } from 'react-router-dom'
import { LOCALES } from '../locales/locales'
import { TLanguages } from '../models/ILocales'

interface ReviewsPanels {
    name: string
    link: string
    logo: string
}

const Reviews = () => {
    const [currentLocale] = useState<TLanguages>(localStorage.getItem('currentLocale') as TLanguages)
    const [reviewsPanels] = useState<ReviewsPanels[]>([
        {
            name: LOCALES[currentLocale].CREATE_NEW_REVIEW_PAGE,
            link: '/admin/reviews/add',
            logo: ReviewSVG
        },
        {
            name: LOCALES[currentLocale].SHOW_ALL_REVIEW_PAGE,
            link: '/admin/reviews/edit',
            logo: ReviewSVG
        },
        {
            name: LOCALES[currentLocale].STATISTIC,
            link: '/admin/reviews/stats',
            logo: ReviewSVG
        }
    ])

    return (
        <MainLayout>
            <div className="w-full h-full flex flex-row flex-wrap justify-center gap-3">
                {reviewsPanels.map(item => (
                    <NavLink
                        to={item.link}
                        className="flex flex-col w-80 h-32 p-5 border-blue-500 border rounded-2xl"
                        key={item.name}
                    >
                        <div className="flex items-start gap-3">
                            <img
                                src={item.logo}
                                alt={item.name}
                                className="w-7 h-7 svg-blue"
                            />
                            <span>{item.name}</span>
                        </div>
                    </NavLink>
                ))}
            </div>
        </MainLayout>
    )
}

export default Reviews
